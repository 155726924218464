import React from 'react';
// import { MDBAnimation } from "mdbreact";
import {Link} from 'gatsby';
// import 'bootstrap';
import QAbg from '../../assets/images/services/quality-assurance/topbg-quality_assurance.png';

const $ = typeof window !== `undefined` ? require('jquery') : null;

require('./detailservices.css');

class QualityAssurance extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  shouldComponentUpdate() {
    // remove when no jquery is used
    return false;
  }

  render() {
    return (
      <>
        <div>
          <section
            className="service-individual-page content-section-header"
            style={{backgroundImage: `url(${QAbg})`}}
          >
            <div className="container d-flex h-100">
              <div className="row justify-content-center align-items-center">
                <div className="col-12 justify-content-center align-items-center">
                  <h1 className="latoBlack text-left text-white heading">Quality Assurance</h1>
                  <p className="latoRegular text-left text-white description">
                    We design our tests systematically to ensure end-to-end user satisfaction,
                    through improved product quality and a delightful digital experience.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="services-skills mt-5">
            <div className="container">
              <div className="row justify-content-center align-items-center">
                <div className="col-12">
                  <div className="mb-4">
                    <h1 className="latoBlack text-left heading">Skill Set</h1>
                    <p className="latoRegular text-left description">
                      Our QA engineers skilfully test the final product for its usage, user
                      interface, data integrity and security to ensure the product is meeting all
                      its specified requirements.
                    </p>
                    <div className="row">
                      <div className="col-lg-2 text-center px-0 col-6 col-md-3">
                        <img
                          width={40}
                          height={40}
                          src={
                            require('../../assets/images/services/quality-assurance/skillset/manual-qa-testing.svg')
                              .default
                          }
                          alt="manual-qa-testing"
                        />
                        <p className="text-center latoRegular icon-descriptions">
                          Manual QA
                          <br />
                          Testing
                        </p>
                      </div>
                      <div className="col-lg-2 text-center px-0 col-6 col-md-3">
                        <img
                          width={40}
                          height={40}
                          src={
                            require('../../assets/images/services/quality-assurance/skillset/auto-qa-testing.svg')
                              .default
                          }
                          alt="Auto QA testing"
                        />
                        <p className="text-center latoRegular icon-descriptions">
                          Automated QA
                          <br />
                          Testing
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5">
                    <h1 className="latoBlack text-left heading">Tool Set</h1>
                    <p className="latoRegular text-left description">
                      To aid their product testing and ensuring a high quality standard, they use
                      multiple tools for their testing process.
                    </p>
                    <div className="row">
                      <div className="col-lg-2 text-center px-0 col-6 col-md-3">
                        <img
                          className="img-fluid"
                          src={
                            require('../../assets/images/services/quality-assurance/toolset/selenium.png')
                              .default
                          }
                          alt="Selenium"
                        />
                        <p className="text-center latoRegular icon-descriptions">Selenium</p>
                      </div>
                      <div className="col-lg-2 text-center px-0 col-6 col-md-3">
                        <img
                          className="img-fluid"
                          src={
                            require('../../assets/images/services/quality-assurance/toolset/espresso-ui-testing.png')
                              .default
                          }
                          alt="Espresso UI Testing"
                        />
                        <p className="text-center latoRegular icon-descriptions">
                          Espresso UI Testing
                        </p>
                      </div>
                      <div className="col-lg-2 text-center px-0 col-6 col-md-3">
                        <img
                          className="img-fluid"
                          src={
                            require('../../assets/images/services/quality-assurance/toolset/junit.png')
                              .default
                          }
                          alt="JUnit"
                        />
                        <p className="text-center latoRegular icon-descriptions">JUnit</p>
                      </div>
                      <div className="col-lg-2 text-center px-0 col-6 col-md-3">
                        <img
                          className="img-fluid"
                          src={
                            require('../../assets/images/services/quality-assurance/toolset/xctest.png')
                              .default
                          }
                          alt="XCTest"
                        />
                        <p className="text-center latoRegular icon-descriptions">XCTest</p>
                      </div>
                      <div className="col-lg-2 text-center px-0 col-6 col-md-3">
                        <img
                          className="img-fluid"
                          src={
                            require('../../assets/images/services/quality-assurance/toolset/appium.png')
                              .default
                          }
                          alt="Appium"
                        />
                        <p className="text-center latoRegular icon-descriptions">Appium</p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 mb-5">
                    <h1 className="latoBlack text-left heading-portfolio">Portfolio</h1>
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-12">
                        <div className="box w-100">
                          <img
                            className="img-fluid w-100"
                            src={
                              require('../../assets/images/services/projects/draftkings-service.png')
                                .default
                            }
                            alt="DraftKings-Service"
                          />
                          <div className="overlay d-flex">
                            <div className="w-100 h-100 d-flex justify-content-center align-items-center  overlay-text text-center">
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://itunes.apple.com/us/app/draftkings-fantasy-sports/id710535379?mt=8"
                              >
                                <img
                                  className="img-fluid"
                                  src={
                                    require('../../assets/images/services/app-store.png').default
                                  }
                                />
                                <p className="description text-white text-center">App store</p>
                              </a>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                className="store-link"
                                href="https://play.google.com/store/apps/details?id=com.draftkings.dklive&hl=en"
                              >
                                <img
                                  className="img-fluid"
                                  src={
                                    require('../../assets/images/services/play-store.png').default
                                  }
                                />
                                <p className="description text-white text-center">Play Store</p>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-12">
                        <div className="box w-100">
                          <img
                            className="w-100 img-fluid"
                            src={
                              require('../../assets/images/services/projects/lifeprint-service.png')
                                .default
                            }
                            alt="lifeprint-service"
                          />
                          <div className="overlay d-flex">
                            <div className="w-100 h-100 d-flex justify-content-center align-items-center  overlay-text text-center">
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://itunes.apple.com/us/app/lifeprint-photos/id972104022?mt=8"
                              >
                                <img
                                  className="img-fluid"
                                  src={
                                    require('../../assets/images/services/app-store.png').default
                                  }
                                />
                                <p className="description text-white text-center">App store</p>
                              </a>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://play.google.com/store/apps/details?id=com.lifeprint.lifeprintphotos&hl=en"
                              >
                                <img
                                  className="img-fluid"
                                  src={
                                    require('../../assets/images/services/play-store.png').default
                                  }
                                />
                                <p className="description text-white text-center">Play Store</p>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-12">
                        <div className="box w-100 mb-0 text-left">
                          <img
                            className="img-fluid w-100"
                            src={
                              require('../../assets/images/services/projects/decorist-service.png')
                                .default
                            }
                            alt="decorist-service"
                          />
                          <div className="overlay d-flex">
                            <div className="w-100 h-100 d-flex justify-content-center align-items-center  overlay-text text-center">
                              <Link to="/portfolio/decorist-room-builder">
                                <img
                                  className="img-fluid"
                                  src={
                                    require('../../assets/images/services/website-link.png').default
                                  }
                                />
                                <p className="description text-white text-center">Case study</p>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default QualityAssurance;
